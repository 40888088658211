/**
 * @file CSV 生成器
 */

import { isNullOrUndefined } from '@/common/tools';

export default function exportCSV(data, columns, options = {}) {
    let csvStr = '';
    csvStr += genCSVDataHead(columns);
    data.forEach(element => {
        csvStr += genCSVDataRow(element, columns);
    });
    let blob = new Blob(['\uFEFF' + csvStr], {
        type: 'text/plain;charset=utf-8',
    });
    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = options.file || 'export_file.csv';
    link.click();
}

function genCSVDataHead(columns) {
    return columns.map(item => item.label).join(',') + '\r\n';
}

function genCSVDataRow(dataRow, columns) {
    let rowItems = [];
    columns.forEach(element => {
        rowItems.push(
            !isNullOrUndefined(dataRow[element.key]) ? dataRow[element.key] : ''
        );
    });
    return rowItems.join(',') + '\r\n';
}

<template>
    <div class="multi-dimension-cost-view">
        <div class="ctrl-bar">
            <div class="label">视角：</div>
            <n-select
                v-model:value="dimension"
                :options="dimensionOpts"
                class="input"
                size="small"
                style="width: 100px"
                @update:value="onQueryChange"></n-select>
            <div class="label">日期：</div>
            <n-date-picker
                size="small"
                class="input"
                style="width: 300px"
                v-model:value="dateRange"
                type="daterange"
                :is-date-disabled="disabledDate"
                @update:value="onQueryChange"></n-date-picker>
            <div class="label">聚合：</div>
            <n-radio-group
                size="small"
                class="input"
                v-model:value="aggregation">
                <n-radio-button value="day">日</n-radio-button>
                <n-radio-button value="month">月</n-radio-button>
            </n-radio-group>
            <div class="label">展示：</div>
            <n-radio-group size="small" class="input" v-model:value="showStyle">
                <n-radio-button value="table">
                    <n-icon><TableViewFilled /></n-icon>
                </n-radio-button>
                <n-radio-button value="chart">
                    <n-icon><ShowChartFilled /></n-icon>
                </n-radio-button>
            </n-radio-group>
            <n-button
                class="input"
                size="small"
                circle
                quaternary
                @click="showInfoDialog">
                <template #icon>
                    <NIcon>
                        <InfoFilled />
                    </NIcon>
                </template>
            </n-button>
            <n-button class="submit" size="tiny" text @click="onCSVExportClick">
                <template #icon>
                    <n-icon>
                        <table-view-filled />
                    </n-icon>
                </template>
                导出CSV
            </n-button>
        </div>
        <div class="content">
            <div v-if="showStyle === 'chart'" class="trend-chart">
                <ChartBox
                    name="趋势图"
                    :loading="loading"
                    @mounted="renderChart"
                    @resize="onViewResize"></ChartBox>
            </div>
            <n-data-table
                v-if="showStyle === 'table'"
                size="small"
                :loading="loading"
                :data="tableData"
                :columns="tableColumns"
                :single-line="false"
                :scroll-x="tableXScroll"
                :max-height="tableHeight"></n-data-table>
        </div>
        <n-modal v-model:show="infoModalDisplay">
            <n-card title="说明" style="width: 500px" size="small">
                <div class="info-modal">
                    <div class="label">部门视角gap的原因</div>
                    月度数据在T-2产生，但不是在T-2稳定不变，而这里的天级数据，是通过连续2天的月度数据相减得到的，所以二者有gap
                    <div class="label">云商视角gap的原因</div>
                    无论天还是月，都是直接从运维的API获取的数据。就截图的情况来看，二者存在的gap是因为缺少历史数据。如果以后整个月数据完整，还是有gap的话，就需要和运维核对数据。因为云商视角的数据，finops没有做任何处理。
                    <div class="label">
                        如有疑问可联系arya，long.cai，gaojianwen
                    </div>
                </div>
            </n-card>
        </n-modal>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.multi-dimension-cost-view {
    .common-content;

    .ctrl-bar {
        .common-ctrl-bar;
    }
    .content {
        margin-top: 10px;
        background-color: #fff;

        .no-data-view {
            font-size: 16px;
            line-height: 100px;
            text-align: center;
            padding: 0;
            color: #999;
        }

        .trend-chart {
            position: relative;
            height: 500px;
            .standard-border;
            .standard-shadow;
        }
    }
}

.info-modal {
    .label {
        font-weight: bold;
        margin: 4px 0;
    }
}
</style>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    NButton,
    NIcon,
    NSelect,
    NModal,
    NCard,
    NDataTable,
    NDatePicker,
    NRadioButton,
    NRadioGroup,
    useMessage,
} from 'naive-ui';
import { InfoFilled, TableViewFilled, ShowChartFilled } from '@vicons/material';

import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { AdvancedTooltip, yAxisLabelFormatter } from '@/common/EChartsTools';
import { formatNumber, formatAxisData } from '@/common/tools';
import ChartBox from '@/components/ChartBox';

import dayjs from 'dayjs';

import { BusinessAnalysisAPI } from '@/common/API';
import { isNullOrUndefined, isEmptyArray } from '@/common/tools';
import exportCSV from '@/common/CSVGenerator';

const route = useRoute();
const router = useRouter();
const message = useMessage();

echarts.use([
    TooltipComponent,
    LegendComponent,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    LineChart,
    UniversalTransition,
]);

let echartInstance = null;

let aTooltip = new AdvancedTooltip();

// 表格组件高度（适配屏幕高度）
let tableHeight = ref(0);

let dimensionOpts = ref([
    {
        label: '云商',
        value: 'cloud',
    },
    {
        label: '部门',
        value: 'final',
    },
]);

let dimension = ref('final');

let dateObj = dayjs().subtract(2, 'day');

let dateRange = ref([dateObj.startOf('month').valueOf(), dateObj.valueOf()]);

let aggregation = ref('day');

watch(aggregation, val => {
    if (showStyle.value === 'chart') {
        setChartOption();
    }
});

let showStyle = ref('table');

function disabledDate(date) {
    return (
        date > dayjs().subtract(2, 'day').valueOf() || // 该报表数据更新时间T-2
        date < dayjs('2023-11-01').valueOf() // 该报表数据起始时间2023-11-01,之前没有
    );
}

let dateRangeQuery = computed(() => {
    return {
        start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
        end: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
    };
});

let loading = ref(false);

function onQueryChange() {
    router.replace({
        query: {
            dimension: dimension.value,
            start: dateRange.value[0],
            end: dateRange.value[1],
        },
    });
    loadData();
}

let tableXScroll = computed(() =>
    isEmptyArray(tableData.value)
        ? 800
        : Object.keys(tableData.value[0]).length * 100
);

let originData = ref([]);

let tableColumns = computed(() => {
    if (originData.value.length < 1) {
        return [];
    }
    return genTableColumns(Object.keys(originData.value[0]));
});

let tableData = computed(() => {
    if (aggregation.value === 'day') {
        return originData.value;
    }
    let monthMap = {};
    function addPerKeys(v1, v2) {
        for (let key in v1) {
            v1[key] += v2[key];
        }
        return v1;
    }
    originData.value.forEach(element => {
        let date = dayjs(element.date);
        let v = { ...element };
        delete v['date'];
        let ymKey = `${date.year()}-${date.month() + 1}`;
        monthMap[ymKey] = monthMap[ymKey] ? addPerKeys(monthMap[ymKey], v) : v;
    });
    let aggrData = [];
    for (let key in monthMap) {
        aggrData.push({ date: key, ...monthMap[key] });
    }
    return aggrData;
});

function loadData() {
    loading.value = true;
    originData.value = [];

    let task =
        dimension.value === 'cloud'
            ? BusinessAnalysisAPI.getDailyCloudCost(
                  dateRangeQuery.value.start,
                  dateRangeQuery.value.end
              )
            : BusinessAnalysisAPI.getDailyBUCost(
                  dateRangeQuery.value.start,
                  dateRangeQuery.value.end
              );
    task.then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }

        originData.value = res.data;
        if (showStyle.value === 'chart') {
            setChartOption();
        }
    }).catch(err => {
        loading.value = false;
        message.error('未知异常导致加载失败');
        console.log(err);
    });
}

const INTERNAL_KEYS = [
    'MTG包含大数据',
    'NX',
    'GA',
    'XMP',
    'CPL&CPA',
    '不分摊',
    // 'EG',
    'Eg-ads',
    'Eg-cas',
    'Eg-xmp',
    'Eg-dmp',
    'Eg-tkio',
    'Eg-归因产品',
];

function genTableColumns(keys) {
    const vrender = (row, key) =>
        isNullOrUndefined(row[key]) ? '-' : row[key].toFixed(2);
    let newKeys = keys.filter(key => key !== 'date');
    let cols = {
        title: '日期',
        key: 'date',
        width: 100,
    };
    let valueCellStyle = { align: 'right', minWidth: 80, resizable: true };
    if (dimension.value === 'cloud') {
        return [
            cols,
            ...newKeys.map(key => ({
                key,
                title: key,
                ...valueCellStyle,
                render: row => vrender(row, key),
            })),
        ];
    }
    return [
        cols,
        {
            title: '体系内',
            align: 'center',
            children: newKeys
                .filter(key => INTERNAL_KEYS.includes(key))
                .map(key => ({
                    key,
                    title: key,
                    ...valueCellStyle,
                    render: row => vrender(row, key),
                })),
        },
        {
            title: '体系外',
            align: 'center',
            children: newKeys
                .filter(key => !INTERNAL_KEYS.includes(key))
                .map(key => ({
                    key,
                    title: key,
                    ...valueCellStyle,
                    render: row => vrender(row, key),
                })),
        },
    ];
}

let infoModalDisplay = ref(false);

function showInfoDialog() {
    infoModalDisplay.value = true;
}

function onCSVExportClick() {
    let csvColumns = [];
    let columnKeys = Object.keys(tableData.value[0]).filter(
        key => key !== 'date'
    );
    if (dimension.value === 'cloud') {
        csvColumns = columnKeys.map(key => ({
            key,
            label: key,
        }));
    } else {
        let internalColumns = columnKeys
            .filter(key => INTERNAL_KEYS.includes(key))
            .map(key => ({ key, label: `${key}(体系内)` }));
        let externalColumns = columnKeys
            .filter(key => !INTERNAL_KEYS.includes(key))
            .map(key => ({ key, label: key }));
        csvColumns = [...internalColumns, ...externalColumns];
    }
    exportCSV(
        tableData.value,
        [{ key: 'date', label: '日期' }, ...csvColumns],
        {
            file: `multi_dimension_${dimension.value}_cost.csv`,
        }
    );
}

function genChartXAxis() {
    return tableData.value.map(item => item.date);
}

function genChartLegends() {
    if (!tableData.value || tableData.value.length < 1) {
        return [];
    }
    return Object.keys(tableData.value[0]).filter(key => key !== 'date');
}

function setChartOption() {
    let legendData = genChartLegends();
    echartInstance.setOption({
        grid: {
            top: 10,
            left: 56,
            right: 140,
            bottom: 30,
        },
        tooltip: aTooltip.tooltip({
            maxHeight: 300,
        }),
        legend: {
            show: true,
            type: 'scroll',
            data: legendData,
            orient: 'vertical',
            right: 20,
            top: 'middle',
            textStyle: {
                width: 80,
                overflow: 'breakAll',
            },
            selector: [
                {
                    // 全选
                    type: 'all',
                    // 可以是任意你喜欢的标题
                    title: '全选',
                },
                {
                    // 反选
                    type: 'inverse',
                    // 可以是任意你喜欢的标题
                    title: '反选',
                },
            ],
        },
        xAxis: {
            type: 'category',
            data: genChartXAxis(),
        },
        yAxis: {
            type: 'value',
            ...yAxisLabelFormatter(),
        },
        series: legendData.map(key => {
            return {
                name: key,
                type: 'line',
                showSymbol: false,
                data: tableData.value.map(item => +item[key].toFixed(2)),
                triggerLineEvent: true,
            };
        }),
    });
}

function renderChart(dom) {
    echartInstance = echarts.init(dom);
    setChartOption();
}

function onViewResize({ width }) {
    echartInstance.resize({ width });
}

// lifecycle
onMounted(() => {
    tableHeight.value = document.body.clientHeight - 220;
    if (route.query.dimension) {
        dimension.value = route.query.dimension;
    }
    if (route.query.start) {
        dateRange.value = [+route.query.start, dateRange.value[1]];
    }
    if (route.query.end) {
        dateRange.value = [dateRange.value[0], +route.query.end];
    }
    loadData();
});

window.addEventListener('resize', () => {
    tableHeight.value = document.body.clientHeight - 220;
});
</script>
